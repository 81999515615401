import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Icon } from "react-icons-kit"
import { phone } from "react-icons-kit/feather/phone"
import { envelopeO } from "react-icons-kit/fa/envelopeO"
import { ic_content_copy } from "react-icons-kit/md/ic_content_copy"

const Footer = props => {
  const copyToClipboard = () => {
    if (typeof navigator !== "undefined")
      navigator.clipboard.writeText("contato@multilogica.ind.br")
    document.getElementById("copied").textContent = "copied"
    setTimeout(() => {
      document.getElementById("copied").textContent =
        "contato@multilogica.ind.br"
    }, "1000")
  }

  return (
    <StaticQuery
      query={graphql`
        query footerEN {
          logoMultilogicaWhite: file(
            relativePath: { eq: "logo-multilogica-white.png" }
          ) {
            childImageSharp {
              id
              gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 280)
            }
          }
        }
      `}
      render={data => (
        <footer className="footer bg-mi-blue text-white">
          <div className="h-10" />
          <div className="container mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-12 gap-4 border-b pb-4">
              <div className="md:col-span-4 text-center">
                <div className="mx-auto">
                  <GatsbyImage
                    image={getImage(data.logoMultilogicaWhite)}
                    backgroundColor={false}
                    alt="Multilogica Ltda"
                  />
                </div>
              </div>
              <div className="col-span-1 md:col-span-4 text-lg">
                <div className="mx-auto w-fit mt-8">
                  <p>Products</p>
                  <Link to="/en/mammut">
                    <p className="font-light my-2.5">&nbsp;&nbsp;&nbsp;&nbsp;Mammut</p>
                  </Link>
                  <Link to="/en/beckmann">
                    <p className="font-light my-2.5">
                      &nbsp;&nbsp;&nbsp;&nbsp;Beckmann
                    </p>
                  </Link>
                  <Link to="/en/services">
                    <p className="my-2.5">Services</p>
                  </Link>
                  <Link to="/en/spare-parts">
                    <p className="my-2.5">Spare parts</p>
                  </Link>
                  <Link to="/en/contact">
                    <p className="my-2.5">Contact/Sales</p>
                  </Link>
                  <Link to="/en/about-us">
                    <p className="my-2.5">About us</p>
                  </Link>
                  <Link to="/en/privacy-policy">
                    <p className="my-2.5">Privacy policy</p>
                  </Link>
                </div>
              </div>
              <div className="col-span-1 md:col-span-4 text-center">
                <div className="text-center mt-8">
                  <div>
                    <div className="grid grid-cols-1 gap-4 mx-auto w-fit">
                      <div className="">
                        <div className="text-mi-yellow float-left mr-4">
                          <Icon size={"36px"} icon={phone} />
                        </div>
                        <div className="float-left mt-1">+55 11 4319-1041</div>
                      </div>

                      <div className="clear-both">
                        <div className="text-mi-yellow float-left mr-4">
                          <Icon size={"36px"} icon={envelopeO} />
                        </div>
                        <div
                          onClick={e => copyToClipboard()}
                          onKeyDown={() => null}
                          role="button"
                          tabIndex={0}
                          className="float-left mt-1 text-center"
                        >
                          <Icon
                            size={"18px"}
                            icon={ic_content_copy}
                            className=" float-right"
                          />

                          <div
                            id="copied"
                            className=" float-right mr-2"
                            style={{ width: "204px" }}
                          >
                            contato@multilogica.ind.br
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-10" />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-12 gap-4 ">
              <div className="col-span-3 text-center"></div>
              <div className="col-span-6 text-center my-6">
                <div className="text-center">
                  <p>
                    Multilógica Ltda© 2018 - {new Date().getFullYear()} All
                    rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    />
  )
}

export default Footer
